import { render, staticRenderFns } from "./RemoteRecord.vue?vue&type=template&id=23f381f1&scoped=true"
import script from "./RemoteRecord.vue?vue&type=script&lang=js"
export * from "./RemoteRecord.vue?vue&type=script&lang=js"
import style0 from "./RemoteRecord.vue?vue&type=style&index=0&id=23f381f1&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f381f1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oliver/Documents/jiashi/jiashi1.0/node_modules/.store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23f381f1')) {
      api.createRecord('23f381f1', component.options)
    } else {
      api.reload('23f381f1', component.options)
    }
    module.hot.accept("./RemoteRecord.vue?vue&type=template&id=23f381f1&scoped=true", function () {
      api.rerender('23f381f1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/RemoteRecord.vue"
export default component.exports