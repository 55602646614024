<template>
  <div class="js-common-wrap js-remote-record">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">远程协作记录</label>
        <el-badge value="new" class="item">
          <el-button style="margin-left: 20px" @click="showRating"
            >评分</el-button
          >
        </el-badge>
        <AddTags
          :id="details.id"
          type="remote"
          :show-tags="details.tags"
          @after-edit="getDetails"
        />
      </div>
    </div>
    <div class="js-common-content">
      <div class="js-remote-content">
        <div class="video-info">
          <div id="mediaBox"></div>
          <ul class="video-info-details">
            <li>
              <label>频道号：</label><span>{{ details.channel }}</span>
            </li>
            <li>
              <label>发起人：</label><span>{{ details.caller }}</span>
            </li>
            <li>
              <label>会话时长：</label><span>{{ details.duration }}</span>
            </li>
            <li>
              <label>开始时间：</label><span>{{ details.gmtCreated }}</span>
            </li>
            <li>
              <label>结束时间：</label><span>{{ details.gmtUpdated }}</span>
            </li>
            <li>
              <label>参与人：</label><span>{{ details.attendeeUserName }}</span>
            </li>
            <li>
              <label>备注：</label><span>{{ details.remark }}</span>
            </li>
            <li>
              <label>评分：</label
              ><span v-for="(rate, index) in details.gradeList" :key="index">{{
                `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
              }}</span>
            </li>
          </ul>
        </div>
        <div class="chat-info">
          <div class="chat-info-head">聊天详情</div>
          <div
            class="chat-info-details"
            :class="
              details.rtmMsgList && details.rtmMsgList.length ? '' : 'no-data'
            "
          >
            <ul v-if="details.rtmMsgList && details.rtmMsgList.length">
              <li v-for="info in details.rtmMsgList" :key="info.id">
                <template v-if="info.type > 30 && info.type < 40">
                  <div>
                    <p class="name" :class="info.oneself ? 'self' : ''">
                      {{ info.userRealName }}
                    </p>
                    <p
                      class="info"
                      v-if="info.type == 31"
                      :class="info.oneself ? 'self' : ''"
                    >
                      <span class="text">{{ info.content }}</span>
                    </p>
                    <p
                      class="info"
                      v-if="info.type == 32"
                      :class="info.oneself ? 'self' : ''"
                    >
                      <el-image
                        class="img"
                        :src="info.content"
                        :preview-src-list="[info.content]"
                      ></el-image>
                    </p>
                    <p
                      class="info"
                      v-if="info.type == 33"
                      :class="info.oneself ? 'self' : ''"
                    >
                      <span class="href">
                        <a :href="info.content" target="_blank">点击下载文件</a>
                      </span>
                    </p>
                  </div>
                </template>
                <p v-else class="sysInfo">{{ info.content }}</p>
              </li>
            </ul>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <Rating v-model="show.rating" type="remote" />
  </div>
</template>

<script>
import Player from "xgplayer";
import Rating from "@/components/Rating";
import AddTags from "@/components/AddTags";

export default {
  name: "",
  components: {
    Player,
    Rating,
    AddTags,
  },
  data() {
    return {
      show: {
        rating: false,
      },
      media: {
        id: "mediaBox",
        url: "",
        fluid: true,
      },
      currentID: "",
      details: {
        channel: "",
        caller: "",
        duration: "",
        gmtCreated: "",
        gmtUpdated: "",
        attendeeUserName: "",
        remark: "",
        rtmMsgList: [],
      },
    };
  },
  mounted() {
    this.currentID = this.$route.query.id;
    this._initPlayer();
    this.getDetails();
  },
  methods: {
    showRating() {
      this.show.rating = true;
    },
    getDetails() {
      this.$axios({
        method: "get",
        url: `api/csp/rc/v1/session/${this.currentID}`,
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.details = data.data;
          this.details.tags = data.data.tags ? data.data.tags.split(",") : [];
          console.log(this.details)
          this.media.url = this.details.videoUrl;
          this._initPlayer();
        }
      });
    },
    _initPlayer() {
      new Player(this.media);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/remoteRecord.scss";
</style>
