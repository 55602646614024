var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-remote-record" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("远程协作记录")]),
            _c(
              "el-badge",
              { staticClass: "item", attrs: { value: "new" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "20px" },
                    on: { click: _vm.showRating },
                  },
                  [_vm._v("评分")]
                ),
              ],
              1
            ),
            _c("AddTags", {
              attrs: {
                id: _vm.details.id,
                type: "remote",
                "show-tags": _vm.details.tags,
              },
              on: { "after-edit": _vm.getDetails },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "js-common-content" }, [
        _c("div", { staticClass: "js-remote-content" }, [
          _c("div", { staticClass: "video-info" }, [
            _c("div", { attrs: { id: "mediaBox" } }),
            _c("ul", { staticClass: "video-info-details" }, [
              _c("li", [
                _c("label", [_vm._v("频道号：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.channel))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("发起人：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.caller))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("会话时长：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.duration))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("开始时间：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.gmtCreated))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("结束时间：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.gmtUpdated))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("参与人：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.attendeeUserName))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("备注：")]),
                _c("span", [_vm._v(_vm._s(_vm.details.remark))]),
              ]),
              _c(
                "li",
                [
                  _c("label", [_vm._v("评分：")]),
                  _vm._l(_vm.details.gradeList, function (rate, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        _vm._s(
                          `${rate.raterUserName}给${rate.rateeUserName}评分为${rate.grade}；`
                        )
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c("div", { staticClass: "chat-info" }, [
            _c("div", { staticClass: "chat-info-head" }, [_vm._v("聊天详情")]),
            _c(
              "div",
              {
                staticClass: "chat-info-details",
                class:
                  _vm.details.rtmMsgList && _vm.details.rtmMsgList.length
                    ? ""
                    : "no-data",
              },
              [
                _vm.details.rtmMsgList && _vm.details.rtmMsgList.length
                  ? _c(
                      "ul",
                      _vm._l(_vm.details.rtmMsgList, function (info) {
                        return _c(
                          "li",
                          { key: info.id },
                          [
                            info.type > 30 && info.type < 40
                              ? [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "name",
                                        class: info.oneself ? "self" : "",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(info.userRealName) + " "
                                        ),
                                      ]
                                    ),
                                    info.type == 31
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "info",
                                            class: info.oneself ? "self" : "",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [_vm._v(_vm._s(info.content))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    info.type == 32
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "info",
                                            class: info.oneself ? "self" : "",
                                          },
                                          [
                                            _c("el-image", {
                                              staticClass: "img",
                                              attrs: {
                                                src: info.content,
                                                "preview-src-list": [
                                                  info.content,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    info.type == 33
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "info",
                                            class: info.oneself ? "self" : "",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "href" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: info.content,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [_vm._v("点击下载文件")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              : _c("p", { staticClass: "sysInfo" }, [
                                  _vm._v(_vm._s(info.content)),
                                ]),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _c("el-empty", { attrs: { description: "暂无数据" } }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("Rating", {
        attrs: { type: "remote" },
        model: {
          value: _vm.show.rating,
          callback: function ($$v) {
            _vm.$set(_vm.show, "rating", $$v)
          },
          expression: "show.rating",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }